.sliding {
    max-height: 0;
    overflow: hidden;
    visibility: hidden;

    transition: max-height 0.25s ease-in-out, visibility 0.25s;

    &.toggled {
        max-height: 500px;
        visibility: visible;
    }
}
