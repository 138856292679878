@import "fonts/tbfont";
@import "fonts/lato";

@import "bootstrap/variables";
@import "bootstrap-webCall";

@import "type";
@import "forms";

@import "utility";

@import "components/modals";
@import "components/button-toolbars";
@import "components/range-input";
@import "components/sliding";
@import "components/loader-error";

body {
    padding: 0 15px;
}

.logo {
    margin: 8px auto 5px auto;
}

.pnlWebCallApp {
    position: relative;

    @import "webCallApp";

    .confModeLabel {
        width : 100%;
        margin-bottom : 4px;
        font-weight : bold;
        text-align : center;
        font-size: 16px;

        height : 22px;
    }

    .confMode {
        margin-bottom : 4px;
    }

    .debug {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 300px;
        right: 0;
        overflow-y: scroll;

        > div {
            display: inline-block;
            vertical-align: top;

            &:not(:last-child) {
                margin-right: 10px;
            }
        }

        .debug-status {
            width: 300px;
            font-size: 12px;
            table {
                td:first-child {
                    font-weight: bold;
                    width: 110px;
                }
            }
        }

        code {
            white-space: pre-wrap;
            word-wrap: break-word;

            padding: 2px 4px;
            font-size: 90%;
            font-family: @font-family-monospace;

            color: @code-color;
            background-color: @code-bg;
        }

        h5 {
            font-size: 14px;
            margin-top: 7px;
            margin-bottom: 7px;
        }
    }

    &.disable-command-buttons {
        .btn-panel, .confModeLabel, .btn-QualityMeterButton {
            display: none !important;
        }

        .connecting .status {
            height: auto !important;
            margin-bottom: 5px;
        }
    }
}

@import "components/loader";
